<template>
  <div data-id="lottie" class="lottie-wrapper mt-64 mt-md-0">
    <atom-lottie
      :name="Animations.COURIER_SEARCH"
      :loop="true"
      @complete="emits(Emits.COMPLETE, $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import { Animations, Emits } from '~common/enums'
import type { IndexLottieEmits } from '~common/types'

const AtomLottie = defineAsyncComponent(() => import('~components/atoms/AtomLottie.vue'))

const emits = defineEmits<IndexLottieEmits>()
</script>

<style lang="scss" scoped>
.lottie-wrapper {
  width: 125px;
  height: 130px;
}

@media (min-width: $media-md) and (max-width: $media-lg) {
  .lottie-wrapper {
    height: 165px;
    width: 160px;
    overflow: hidden;
  }
}

@media (min-width: $media-lg) {
  .lottie-wrapper {
    width: 288px;
    height: auto;
  }
}
</style>
